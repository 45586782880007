import "./App.css";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";

//Material UI Stuff
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

//Main Function
function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [apartments, setApartments] = useState([]);
  const [open, setOpen] = useState(false);

  //calls the Api from the Server
  useEffect(() => {
    fetch("http://159.69.39.179/api/reservations")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setApartments(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  //Defines today Date in Format: YYYY-MM-DD
  const today = new Date().toISOString().slice(0, 10);

  //Store Apartment Data to this value for the Email
  let apartmentDataName = apartments.map((item) =>
    item.departure === today ? " " + item.apartment.name : ""
  );

  const classes = useStyles();

  //send Email to Server
  const handleClick = () => {
    setOpen(true);
    Axios({
      url: "http://159.69.39.179/email",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ apartmentDataName }),
    })
      .then((response) => {
        response.json();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //Render Stuff for the MainContent
  if (error) {
    return <div className="loadingAndError">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="loadingAndError">Loading...</div>;
  } else {
    return (
      <div className="cardMain">
        <h1 className="heading">Reinigungskalender für Ferienapartments</h1>
        <div className="mainStyle">
          {apartments.map((item) =>
            item.departure === today ? (
              <div className="cardItems" key={item.id}>
                <h2>{item.apartment.name}</h2>
                <p>
                  Dieses Apartment muss heute bis 15:00 Uhr gereinigt werden.
                </p>
              </div>
            ) : (
              ""
            )
          )}
        </div>
        <p className="finish">
          <h3>
            Möchten Sie die Reinigung für die oben genannten Apartments
            abschließen?
          </h3>
          <Button
            variant="outlined"
            color="secondary"
            id="buttonimportant"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={handleClick}
          >
            Reinigung abschließen
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Reinigung wurde erfolgreich abgeschlossen!
            </Alert>
          </Snackbar>
        </p>
      </div>
    );
  }
}

export default App;
